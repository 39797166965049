import React from "react"
import theme from "theme"
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Про послуги | Breez</title>
        <meta
          name={"description"}
          content={"Втілення вашого бачення в життя"}
        />
        <meta property={"og:title"} content={"Про послуги  | Breez"} />
        <meta
          property={"og:description"}
          content={"Втілення вашого бачення в життя"}
        />
        <meta
          property={"og:image"}
          content={"https://breezifyfix.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://breezifyfix.com/img/678548856.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://breezifyfix.com/img/678548856.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://breezifyfix.com/img/678548856.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://breezifyfix.com/img/678548856.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://breezifyfix.com/img/678548856.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://breezifyfix.com/img/678548856.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-6">
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          grid-gap="56px 84px"
          margin="0px 0px 40px 0px"
          lg-grid-template-columns="1fr"
          sm-grid-gap="36px 24px"
        >
          <Box
            min-width="100px"
            min-height="100px"
            lg-padding="0px 150px 0px 0px"
            md-padding="0px 0 0px 0px"
          >
            <Text
              margin="0px 0px 20px 0px"
              font="normal 700 42px/1.2 --fontFamily-sans"
            >
              Ознайомтеся з нашим комплексним обслуговуванням заходів
            </Text>
            <Text
              margin="0px 0px 10px 0px"
              font="normal 300 22px/1.5 --fontFamily-sansHelvetica"
            >
              Ласкаво просимо на сторінку послуг Breez, де ми втілюємо ваші мрії
              в реальність за допомогою нашого експертного планування та
              проведення заходів. Ми пропонуємо широкий спектр послуг,
              спрямованих на те, щоб зробити вашу подію бездоганною, незабутньою
              та неординарною. Дозвольте нам ознайомитися з нашими пропозиціями
              та показати, як ми можемо зробити вашу наступну подію справді
              особливою.
            </Text>
          </Box>
          <Image
            src="https://breezifyfix.com/img/7.jpg"
            display="block"
            width="100%"
            height="100%"
            object-fit="cover"
            margin="0px 0px 0px 0"
            lg-order="-1"
            lg-height="500px"
            md-height="400px"
          />
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            width="100%"
            height="auto"
            padding="0px 0px 70% 0px"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
          >
            <Image
              position="absolute"
              width="100%"
              top="auto"
              left={0}
              src="https://breezifyfix.com/img/8.jpg"
              object-fit="cover"
              display="block"
              right={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            sm-font="--headline3"
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            md-text-align="left"
          >
            Наша команда експертів
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            Наша команда досвідчених професіоналів є основою Breez. Володіючи
            багатими знаннями та прагненням до досконалості, члени нашої команди
            невтомно працюють, щоб забезпечити успіх кожного заходу. Від
            планувальників і дизайнерів до координаторів і допоміжного
            персоналу, кожен член команди приносить свої унікальні навички та
            досвід, забезпечуючи безперебійну роботу без стресів.
          </Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />

		<Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Планування та проведення заходів
          </Text>
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://breezifyfix.com/img/9.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Індивідуальне планування заходів: наша команда тісно співпрацює з
            вами, щоб зрозуміти ваше бачення та створити індивідуальний план,
            який відображає ваш стиль і вподобання.
            <br />
            <br />
            Початкова консультація: ми починаємо з детальної консультації, щоб
            зрозуміти ваші цілі, бюджет і бачення події.
            <br />
            <br />
            Планування та координація: наші планувальники координують кожну
            деталь, від вибору місця проведення до остаточного виконання,
            забезпечуючи плавний та безперебійний процес.
            <br />
            <br />
            День координації: у день заходу наші координатори знаходяться на
            місці, щоб контролювати кожну деталь, дозволяючи вам розслабитися та
            насолодитися подією.
          </Text>
        </Box>
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Вибір місця та дизайн: пошук ідеального місця та створення
            захоплюючої обстановки є критично важливими для успіху будь-якої
            події.
            <br />
            <br />
            Пошук місць: ми допоможемо вам знайти ідеальне приміщення, яке
            відповідає вашому стилю, бюджету та кількості гостей.
            <br />
            <br />
            Креативний дизайн: наші дизайнери створюють приголомшливий декор і
            макети, які втілюють ваше бачення в життя.
            <br />
            <br />
            Налаштування та поломка: ми беремо на себе всі аспекти налаштування
            та поломки, забезпечуючи вам безпроблемну роботу.
            
          </Text>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Обслуговування та планування меню: смачна їжа та напої є важливими
            для будь-якої події.
            <br />
            <br />
            Налаштування меню: наші кухарі працюють з вами, щоб створити
            індивідуальне меню, яке відповідає вашим смакам і дієтичним
            потребам.
            <br />
            <br />
            Сеанси дегустації: ми пропонуємо сеанси дегустації, щоб
            переконатися, що ви повністю задоволені вибором меню.
            <br />
            <br />
            Професійне обслуговування: наша команда кейтерингу надає професійні
            послуги, гарантуючи, що ваші гості отримають винятковий досвід
            обіду.
          </Text>
        </Box>
      </Section>
      <Components.Contact />
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
